import React from 'react';
import './about.css';

const About = () => {
  return (
    <div className="temp">
      <p>Coming Soon...</p>
    </div>
  )
}

export default About